.card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    transform: scale(1);
    margin-bottom: 20px;
  }


  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    overflow-y: hidden;
  }
  
  .card-box {
    width: calc(33.33% - 30px);
    margin: 10px;
    box-sizing: border-box;
  }
  

  
  .card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    transform: scale(1);
    margin-bottom: 20px;
    height: 400px;

    min-width: 300px;
  }
  
  @media (max-width: 980px) {
    .card {
      width: 300px;
    }
  
    .card-title {
      text-decoration: none !important;
      text-align: left;
      margin: 0;
      font-size: 1.5rem !important;
    }
  
    .card-text {
      text-decoration: none !important;
      text-align: left;
      margin: 0;
      font-size: 1.2rem !important;
    }
    .card-box {
      width: 100%;
      box-sizing: border-box;
      margin: 0 auto;
      padding: 10px;
      border-radius: 0px;
    }
  }
  
  .card-image-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    background-clip: border-box;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin: 0px;
    padding: 0px;
    max-height: 40%;

  }
  
  .card-text-section {
    flex: 1;
    flex-direction: column;
    color: black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    padding-top: 0px;
    }