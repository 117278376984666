.card-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
  }

  .card-list {
    width: 500px;
    padding: 20px;
  }
  
  .Lcard p {
    margin: 0;
    padding: 0%;
    color: #6b6b6b;
    fontSize: 1.3rem;
    lineHeight: 1.6;
    textAlign: left;
  }

  .Lcard {
    width: 50%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    padding: 5px;
    margin: 10px;
    text-align: center;
    text-decoration: none;
  
  }

  .card-list a {
    transition: transform 0.5s ease;
  }
  
  @media (max-width: 768px) {
    .card-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .card-grid {
      grid-template-columns: 1fr;
    }
  }
  


  