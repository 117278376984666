.circle-shape {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-image: url(https://media.licdn.com/dms/image/D4E03AQHD2lXUko2a-Q/profile-displayphoto-shrink_800_800/0/1689686255138?e=2147483647&v=beta&t=TNUbatlCnGYv_Yr-j9-BySJwFKoT9Hygqz4mib_sczo);
    background-size: cover;
    align-self: center;
    display: inline-block;
  }

  .wave {
    display: inline-block;
    transform-origin: 70% 110%;
    animation: wave 2s ease;
    margin: 0;
  }
  @keyframes wave {
    0% {
      transform: rotate(0deg) scale(1);
    }
    25% {
      transform: rotate(-20deg) scale(1.1);
    }
    50% {
      transform: rotate(0deg) scale(1.2);
    }
    75% {
      transform: rotate(20deg) scale(1.1);
    }
    100%{
      transform: rotate(0deg) scale(1);
    }}
