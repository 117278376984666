


.blog-container {
    max-width: 50%;
    background-color: #f6f6f6;
    margin: 10% auto;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);  }  

  @media (max-width: 900px) {
    .blog-container {
      max-width: 100%!important;

      width: 100%;

    }
  
    h2 {
      font-size: 2rem;
    }
  
    p.author {
      font-size: 0.9rem;
    }
  
    p.body {
      font-size: 1.1rem;
    }
  }


  .blog-title.card-title {
    color: #333333;
    font-size: 2rem;
    line-height: 1.2;
    text-align: left;
    margin-bottom: 0;
    margin-top: 10px;
    height: 10%;
    }
    
    .blog-body.card-text {
    color: #6b6b6b;
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: left;
    }

  .blog-container {
    max-width: 50%;
    background-color: #f6f6f6;
    margin: 10% auto;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  }
  
  .blog-content {
    padding: 30px;
  }
  
  .blog-title {
    color: #333333;
    font-size: 2.5rem;
    line-height: 1.2;
    text-align: left;
    margin-bottom: 0;
    margin-top: 10;
  }
  
  .blog-author {
    color: #333333;
    font-size: 1rem;
    line-height: 1;
    text-align: left;
    margin-top: 5px;
    margin-left: 10px;
  }
  
  .blog-divider {
    border: 1px solid #6b6b6b;
    opacity: 0.5;
  }

  .blog-body {
    color: #6b6b6b;
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: left;
  }
  
  .blog-scroll-up-btn {
    fill: #6b6b6b;
    width: 50px;
    height: 50px;
  }

  /* Code block styling */
pre {
    background-color: #f2f2f2;
    border-radius: 5px;
    font-size: 1.1rem;
    line-height: 1.4;
    margin: 0%;
  }
  
  code {
    font-family: 'Courier New', Courier, monospace;
    font-size: 0.8rem;
  }

  @media (max-width: 500px) 
  {
    pre {
      overflow-x: scroll;
      background-color: #f2f2f2;
      border-radius: 5px;
      font-size: 0.8rem;
      line-height: 1.4;
      width: 300px;
    }
  }

  .back-btn {
    position: fixed;
    top: 10px;
    left: 20px;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 40px;
    cursor: pointer;
    outline: none;
  }
  
  .back-btn:hover {
    color: rgba(255, 255, 255, 0.8);
  }